import React, { useEffect, useState } from 'react';
import { AnswerPicker, Intro, Layout } from 'components';
import { statements } from '../statementsAndAnswers';
// import { doubleStatementsForTesting as statements } from '../statementsAndAnswers';
import './index.scss';

const Index = ({ location }) => {
  const blankAnswer = '_________';
  const fiveCards = [];
  const [enterPlayMode, setEnterPlayMode] = useState(false);
  const [finalStatement, setFinalStatement] = useState('');
  const [finalHtmlStatement, setFinalHtmlStatement] = useState('');
  const [statement, setStatement] = useState('');
  const [answerCount, setAnswerCount] = useState(1);
  const [answerReplace, setAnswerReplace] = useState([]);

  useEffect(() => {
    statementPicker();
  }, []);

  // --------------FUNCTION FOR TESTING STATEMENTS MAKE SENSE
  const newStatement = () => {
    const count = (statement ? statement.match(/%/g) : []).length;
    const firstAnswer = answerReplace[0] || blankAnswer;
    const firstHtmlAnswer =
      answerReplace[0] && answerReplace[0] !== blankAnswer ? `<strong>${answerReplace[0]}</strong>` : blankAnswer;
    const secondAnswer = answerReplace[1] || blankAnswer;
    const secondHtmlAnswer = answerReplace[1] ? `<strong>${answerReplace[1]}</strong>` : blankAnswer;
    const thirdAnswer = answerReplace[2] || blankAnswer;
    const thirdHtmlAnswer = answerReplace[2] ? `<strong>${answerReplace[2]}</strong>` : blankAnswer;
    let oneAnswer;
    let oneHtmlAnswer;
    let twoAnswers;
    let twoHtmlAnswers;
    let threeAnswers;
    let threeHtmlAnswers;
    if (count === 1) {
      oneAnswer = statement.replace('%', firstAnswer);
      oneHtmlAnswer = statement.replace('%', firstHtmlAnswer);
      setFinalStatement(oneAnswer);
      setFinalHtmlStatement(oneHtmlAnswer);
    } else if (count === 2) {
      oneAnswer = statement.replace('%', firstAnswer);
      oneHtmlAnswer = statement.replace('%', firstHtmlAnswer);
      twoAnswers = oneAnswer.replace('%', secondAnswer);
      twoHtmlAnswers = oneHtmlAnswer.replace('%', secondHtmlAnswer);
      setFinalStatement(twoAnswers);
      setFinalHtmlStatement(twoHtmlAnswers);
    } else if (count === 3) {
      oneAnswer = statement.replace('%', firstAnswer);
      oneHtmlAnswer = statement.replace('%', firstHtmlAnswer);
      twoAnswers = oneAnswer.replace('%', secondAnswer);
      twoHtmlAnswers = oneHtmlAnswer.replace('%', secondHtmlAnswer);
      threeAnswers = twoAnswers.replace('%', thirdAnswer);
      threeHtmlAnswers = twoHtmlAnswers.replace('%', thirdHtmlAnswer);
      setFinalStatement(threeAnswers);
      setFinalHtmlStatement(threeHtmlAnswers);
    } else {
      setFinalStatement('I have a love/hate relationship with cards against gatsby developers');
      setFinalHtmlStatement('I have a <strong>love/hate</strong> relationship with cards against gatsby developers');
    }
  };

  // ------------

  const statementPicker = () => {
    const index = Math.round(Math.random() * statements.length);
    const nextStatement = statements[index];
    const formattedNextStatement = nextStatement ? nextStatement.replace(/%/g, blankAnswer) : null;
    const count = (nextStatement ? nextStatement.match(/%/g) : []).length;
    setStatement(nextStatement);
    setAnswerCount(count);
    setFinalStatement(formattedNextStatement);
    setFinalHtmlStatement(formattedNextStatement);
  };

  const handleNewAnswer = newAnswer => {
    setAnswerReplace(newAnswer);
    newStatement();
  };

  const handleEnterPlayMode = () => {
    setEnterPlayMode(true);
  };

  const statementComplete = answerReplace.length === answerCount;

  return (
    <Layout location={location}>
      <section className="landing-page">
        <div className="wrapper">
          {enterPlayMode ? (
            <AnswerPicker
              answerCards={fiveCards}
              answerArray={handleNewAnswer}
              answerCount={answerCount}
              newStatement={statementPicker}
              statement={statement}
              statementComplete={statementComplete}
              finalStatement={finalStatement}
              finalHtmlStatement={finalHtmlStatement}
            />
          ) : (
            <Intro handleEnterPlayMode={handleEnterPlayMode} />
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Index;
